import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "lib-flexible-computer"
import {
	language
} from './language/language.js'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
// require('../mock');
import {request} from '@/sujs/http.js'


const app = createApp(App)
app.use(store).use(language).use(router).use(ElementPlus).mount('#app')

app.config.globalProperties.$language = language
app.config.globalProperties.$request = request
 