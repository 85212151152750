export let hayu = {
	now:'hayu',
	fenzhong:'分钟',
	fen:'分',
	miao:'秒',
	queren:'确定',
	fanhui:'返回',
	quxiao:'取消',
	tuchu:'退出',
	defen:'得分',
	is:'是',
	no:'否',
	tishi_error:'很遗憾，又做错了呢，请参考答案解析！',
	tishi_right:'回答正确，你好棒哦！',
	input:'请输入内容',
	time:'考试时间',
	use_time:'考试用时',
	status:'考试状态',
	index_qiehuan:'语言',
	index_kaoshi:'考试',
	index_shouye:'练习',
	index_denglu:'登陆',
	index_jiazhaokaoshi:'驾照考试',
	index_chengji:'成绩',
	shouye_zuo_1: '驾考宝典',
	shouye_zuo_2: '注册立即可享用驾照考试功能',
	shouye_zuo_3: '新疆版本，可同时支持三种语言，汉语·维语·哈语',
	shouye_zuo_4: '账号注册请联系电话：',
	shouye_zuo_5: '400-820-8208',
	shouye_you_1:'注册立即可以考试',
	shouye_you_2:'考试形式多种',
	shouye_you_2_1:'可同时支持“三”种语言',
	shouye_you_3:'监考中心v2.0 AI防作弊',
	shouye_you_3_1:'监考中心v2.0 AI防作弊dwaaaaaa',
	cuoti_timu: '题目内容',
	cuoti_cishu: '错误次数',
	cuoti_time: '更新时间',
	cuoti_search: '搜索题目内容',
	cuoti_train: '错题训练',
	exam_start: '开始考试',
	exam_end_time: '结束时间',
	exam_time: '暂无',
	exam_pic_jiazai: '图片正在加载中',
	exam_no:'暂无',
	grade_name:'考试名称',
	grade_numb:'考试次数',
	grade_score:'最高分',
	grade_ispass:'是否通过',
	grade_last_time:'最后考试时间',
	grade_play:'操作',
	grade_list:'详情',
	grade_error:'错题',
	preview_exam_name: '考试名称',
	preview_exam_time: '考试时长',
	preview_exam_score: '试卷总分',
	preview_exam_pass: '及格分数',
	preview_exam_script: '考试描述',
	preview_exam_type: '开放类型',
	preview_exam_title:'点击`开始考试`后将自动进入考试，请诚信考试！',
	preview_exam_start: '试卷创建成功，即将进入考试！',
	start_time_post: '距离考试结束还有：',
	start_answer_card:'答题卡',
	start_answerno_card:'未作答',
	start_answerok_card:'已作答',
	start_answer_dan:'单选题',
	start_answer_duo:'多选题',
	start_answer_panduan:'判断题',
	start_answer_up:'上一题',
	start_answer_down:'下一题',
	start_answer_post:'交卷',
	start_audio:'您的浏览器不支持 audio 标签。',
	start_jiaojuan_quxiao:'交卷已取消，您可以继续作答！',
	start_jiaojuan_tiaozhuan:'试卷提交成功，即将进入试卷详情！',
	start_jiaojuan:'确认要交卷吗？',
	start_jiaojuan_wite:'正在交卷，请等待...',
	start_jiaojuan_tishi:'您确认交卷吗？共有未答题总数：',
	jiaojuan_name: '考生姓名',
	jiaojuan_time: '考试用时',
	jiaojuan_score: '考试得分',
	jiaojuan_yes: '正确答案',
	jiaojuan_no: '答题结果',
	jiaojuan_no_write: '未答',
	jiaojuan_my_answer: '我的回答',
	ViewQu_jiexi: '整题解析',
	ViewQu_no_jiexi:'暂无解析内容',
	ViewQu_no_choosejiexi:'暂无选项解析',
	lianxi_name1:'驾照练习 科目一',
	lianxi_name2:'驾照练习 科目四',
	lianxi_shuoming1:'科目一相关试题',
	lianxi_shuoming2:'科目四相关试题',
	lianxi_detail:'考试明细',
	practice_datika1 :'当前',
	practice_datika3 :'已做错',
	practice_datika4 :'已做对',
	practice_aideo: '音频解析',
	practice_aideo_click: '点击喇叭播放',
	practice_choose: '选项解析',
	practice_next: '下一题',
	practice_up: '上一题',
	// 新增2022/7/3
	index: '首页',
	kemuyi: '科目一',
	kemusi: '科目四',
	kechengxuanze: '课程选择',
	timuleixing: '题目类型',
	xiaoche: '小车(C1,C2)',
	huoche: '货车 (B1,A1)',
	keche: '客车(A1,B1)',
	motuoke : '摩托车(C4,D,E)',
	danxuan:'单选',
	duoxuan:'多选',
	panduan:'判断',
	// 7-26
	practice_title: '× 错误提示',
	sumbit_last:'提交'
}
