import {hanyu} from './hanyu'
import {hayu} from './hayu'
import {weiyu} from './weiyu'
import store from '../store/index.js'

export function language() {
	let yuyan = store.state.language_num
	if (yuyan == 'hanyu')
		return hanyu
	if (yuyan == 'hayu')
		return hayu
	if (yuyan == 'weiyu')
		return weiyu
}


// 用法
/* 
	在每个vue界面
	created() {
		this.yuyan = this.$language()
	},
	watch:{
		'$store.state.language_num'(to,from){
				this.yuyan = this.$language()
		}
	},
 
 */