export let weiyu = {
	now:'weiyu',
	fenzhong:'مىنوت',
	fen:'نومور',
	miao:'سىكىنوت',
	queren:'مۇقۇملاش',
	fanhui:'返回',
	quxiao:'ئەمەلدىن قالدۇرۇش',
	tuchu:'چىكىنىش',
	defen:'نومور',
	is:'ھەئە',
	no:'ياق',
	tishi_error:'كەچۇرۇڭ خاتا بولوپ قالدى چۇشەندۇرۇشكە قاراڭ',
	tishi_right:'توغرا جاۋاپ بەردىڭىز',
	input:'مەزمۇن كىرگۈزىڭ',
	time:'ئىنتاگان ۋاقتى',
	use_time:'سەرىپ قىلغان ۋاقىت',
	status:'ئىنتاھان ھالىتى',
	index_qiehuan:'تىل',
	index_kaoshi:'ئىنتاھان',
	index_shouye:'مەشىق',
	index_denglu:'كىرىش',
	index_jiazhaokaoshi:'پىراۋا ئىنتاھان',
	index_chengji:'نەتىجە',
	shouye_zuo_1: 'سىستىما',
	shouye_zuo_2: 'تىزىملاتسىڭز ئىشىلتەلەيسىز',
	shouye_zuo_3: 'ئۈچ خىل تىل قوللايدۇ',
	shouye_zuo_4: 'تىزىملاتماقچى بولسىز بۇنومۇر بىلەن ئالاقىلىشىڭ',
	shouye_zuo_5: '18299959592',
	shouye_you_1:'تىزىملىتىپ .ىنتاھان بىرىڭ',
	shouye_you_2:'ھەر تۇرلۇك ئىنتاھانلا',
	shouye_you_2_1:'ئۇچ خىل تىل قوللايدۇ',
	shouye_you_3:'سىستىما',
	shouye_you_3_1:'سىستىما',
	cuoti_timu: 'سوئال مەزمۇنى',
	cuoti_cishu: 'خاتالىق سانى',
	cuoti_time: 'يىڭىلانغان ۋاقىت',
	cuoti_search: 'مەزمۇن ئىزدەش',
	cuoti_train: 'خاتال ئىشلەنگەن سوئاللارنى پۇختىلاش',
	exam_start: 'ئىنتاھان بىرىش',
	exam_end_time: 'تاماملاش ۋاقتى',
	exam_time: 'ۋاقتىنچە يوق',
	exam_pic_jiazai: 'چۇشۇۋاتىدۇ',
	exam_no:'يوق',
	grade_name:'ئىنتاھان ئىسمى',
	grade_numb:'ئىنتاھان قىتىم سانى',
	grade_score:'ئەڭ يۇقۇر نومۇر',
	grade_ispass:'ئۆتتۇممۇ',
	grade_last_time:'ئەڭ ئاخرقى ئىنتاھان بەرگەن ۋاقت',
	grade_play:'مەشخۇلات',
	grade_list:'تەپسىلاتى',
	grade_error:'خاتا ئىشلەنگەنلەر',
	preview_exam_name: 'نامى',
	preview_exam_time: 'ئومومى ۋاقىت',
	preview_exam_score: 'ئومومى نومور',
	preview_exam_pass: 'لاياقەتلىك چىكى',
	preview_exam_script: 'چۇشەندۇرىش',
	preview_exam_type: 'تۇرى',
	preview_exam_title:'باشلاشنى بىسىپ ئىنتاھاننى باشلاڭ',
	preview_exam_start: 'ئىنتاھان باشلىنىۋاتىدۇ',
	start_time_post: 'تەتۇر ساناق',
	start_answer_card:'سوئاللار',
	start_answerno_card:'جاۋاپلانغان',
	start_answerok_card:'جاۋاپلانمىغان',
	start_answer_dan:'تاغ تاللاش',
	start_answer_duo:'كۆپ تاللاش',
	start_answer_panduan:'ھۆكۆم قىلىش',
	start_answer_up:'上一题',
	start_answer_down:'下一题',
	start_answer_post:'提交',
	start_audio:'تور كۆەۇچىڭىز قللىمايدۇ',
	start_jiaojuan_quxiao:'بىكار قىلىندى',
	start_jiaojuan_tiaozhuan:'ئىنتاھانغا كىرىۋاتىدۇ',
	start_jiaojuan:'تاپشۇرامسىز',
	start_jiaojuan_wite:'تاپشۇرۇلۋاتىدۇ',
	start_jiaojuan_tishi:'تاپشۇرامسىز؟ جاۋاپلانمىغانلار ',
	jiaojuan_name: 'ئوقوغۇچى نامى',
	jiaojuan_time: 'سەرىپ قىلغان ۋاقىت',
	jiaojuan_score: 'نەتىجىسى',
	jiaojuan_yes: 'توغا جاۋاپ',
	jiaojuan_no: 'نەتىجە',
	jiaojuan_no_write: 'جاۋاپلانمىغان',
	jiaojuan_my_answer: 'سىزنىڭ جاۋابىڭز',
	ViewQu_jiexi: 'چۇشەندۇرۇشى',
	ViewQu_no_jiexi:'يوق',
	ViewQu_no_choosejiexi:'يوق',
	lianxi_name1:'بىرىنجى تۇر',
	lianxi_name2:'تۆتۈنجى تۇر',
	lianxi_shuoming1:'سىناق بىرىنجى تۇر',
	lianxi_shuoming2:'سىناق ئىنتاھان تۆتىنجى تۇر',
	lianxi_detail:'ئىنتھان تەپسىلاتى',
	practice_datika1 :'ئازىر',
	practice_datika3 :'خاتاسى',
	practice_datika4 :'توغورسى',
	practice_aideo: 'ئاۋازلىق چۇشەندۇرۇش',
	practice_aideo_click: 'لابانى بىسىپ قويوڭ',
	practice_choose: 'چۇشەندۇرۇلىشى',
	practice_next: '下一题',
	practice_up: '上一题',
	index: 'باش بەت',
	kemuyi: '1 _ تۈر',
	kemusi: '4 _ تۈر',
	kechengxuanze: 'ماشىنا تۈرلىرى',
	timuleixing: 'سوئال تۈرى',
	xiaoche: 'كىچىك ماشىنا (C1,C2)',
	huoche: 'يۈك ماشىنا (B1,A1)',
	keche: 'يولوچىلا ماشىنىسى (A1,B1)',
	motuoke : 'موتو (C4,D,E)',
	danxuan:'تاغ تاللاش',
	duoxuan:'كۆپ تاللاش',
	panduan:'ھۆكۆم قىلىش',
	// 7-26
	practice_title: 'خاتالىق ئەسكەرتىش',
	sumbit_last:'提交'
}
