import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import index from '../views/index/index.vue'
import store from '../store/index.js'

const shouye = () => import('views/shouye/shouye')
const exam = () => import('views/exam/exam')
const grade = () => import('views/grade/grade')
const start = () => import('views/start/start')
const preview = () => import('views/start/preview')
const login = () => import('views/login/login')
const result = () => import('views/exam/result')
const detail = () => import('views/shouye/detail')
const routes = [{
		path: '/',
		redirect: '/index',
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: '/404',
		hidden: true
	},
	{
		path: '/404',
		component: () => import('@/views/error-page/404'),
		hidden: true
	},
	{
		path: '/start',
		name: '正在考试',
		component: start,
		meta: {
			// 页面标题title
			title: '正在考试'
		},
	},
	{
		path: '/preview',
		name: '准备考试',
		component: preview,
		meta: {
			// 页面标题title
			title: '准备考试'
		},
	},
	{
		path: '/Register',
		name: 'Register',
		meta: {
			// 页面标题title
			title: '新疆能雅网络科技有限公司'
		},
		component: () => import('@/views/login/register'),
	},
	{
		path: '/result',
		name: 'Result',
		meta: {
			// 页面标题title
			title: '考试结果'
		},
		component: result,
	},
	{
		path: '/detail',
		name: 'Detail',
		meta: {
			// 页面标题title
			title: '考试详情'
		},
		component: detail,
	},
	{
		path: '/practice',
		name: 'practice',
		meta: {
			// 页面标题title
			title: '考试练习'
		},
		component: () => import('@/views/shouye/practice'),
	},
	{
		path: '/login',
		name: '登陆',
		meta: {
			// 页面标题title
			title: '新疆能雅网络科技有限公司'
		},
		component: login,
	},
	{
		path: '/index',
		name: '主页',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: index,
		children: [{
				path: '',
				redirect: '/shouye',
			},
			{
				path: '/shouye',
				name: '首页',
				meta: {
					// 页面标题title
					title: 'Rolum学驾照'
				},
				component: shouye,
			},
			{
				path: '/exam',
				name: '考试',
				component: exam,
				meta: {
					title: '考试'
				}
			},
			{
				path: '/grade',
				name: '成绩',
				meta: {
					// 页面标题title
					title: '成绩'
				},
				component: grade,
			},

			{
				path: '/jiaojuan',
				component: () => import('@/views/jiaojuan/jiaojuan'),
				name: 'ShowExam',
				meta: {
					title: '考试结果'
				}
			},
			{
				path: '/cuoti',
				component: () => import('@/views/cuoti/cuoti'),
				name: 'cuoti',
				meta: {
					title: '错题'
				}
			},
			{
				path: '/ViewQu',
				component: () => import('@/views/cuoti/ViewQu'),
				name: 'ViewQu',
				meta: {
					title: '错题'
				}
			},

			{
				path: '/training/:examId',
				component: () => import('@/views/cuoti/train'),
				name: 'BookTraining',
				meta: {
					title: '错题训练',
					noCache: true,
					activeMenu: '/my/exam/records'
				},
				hidden: true
			}
			/* 			{
							path: '/setinfo',
							name: '修改个人信息',
							component: () => import('@/views/mycenter/setinfo'),
						},
						{
							path: '/vip',
							name: '充值中心',
							component: () => import('@/views/mycenter/vip'),
						}, */
		]

	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	// 从from 跳转到 to 	必须加next不然不会跳转
	// console.log('-------------')
	/* 	console.log(to)
		console.log(from) */
	document.title = to.meta.title
	next()
})
router.afterEach((to, from) => {
	// 跳转完了 不用next了
	// console.log('++++++++++++')
})
export default router
