import { createStore } from 'vuex'

export default createStore({
  state: {
		language_num: 'hanyu',
		is_login: false
  },
  mutations: {
		setLanguage(state,num){
			state.language_num = num
		},
		setLogin(state,num){
			state.is_login = num
		}
  },
  actions: {
  },
  modules: {
  }
})
