<template>
	<div class="index_zhuye">
		<el-container>
			<router-link style="padding:10px 0" class="index" :to="{'name':'首页'}">
				首页
			</router-link>
			<el-header :style="{backgroundColor:color}">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					:background-color="color" text-color="#fff" active-text-color="#ffd04b">
					<el-menu-item index="1" @click="language('hanyu')">汉语</el-menu-item>
					<el-menu-item index="2" @click="language('weiyu')">ئۇيغۇرچە</el-menu-item>
					<el-menu-item index="3" @click="language('hayu')">哈语</el-menu-item>
				</el-menu>
			</el-header>
			<div style="padding:10px 0" class="tuiChu">
				<el-dropdown>
					<span class="el-dropdown-link">
						<el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
						</el-avatar>
						<el-icon class="el-icon--right">
						</el-icon>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item v-if="login_info" @click="onCancel">{{yuyan.index}}</el-dropdown-item>
							<el-dropdown-item v-if="login_info" @click="goCHengji">{{yuyan.index_chengji}}
							</el-dropdown-item>
							<el-dropdown-item v-if="!login_info" @click="dengLu">{{yuyan.index_denglu}}
							</el-dropdown-item>
							<el-dropdown-item v-if="login_info" @click="tuiChu">{{yuyan.tuchu}}</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
				<!-- <el-button type="success" v-if="!login_info" @click="dengLu">{{yuyan.index_denglu}}</el-button>
				<span class="tuchu-but" v-if="login_info" @click="tuiChu">{{yuyan.tuchu}}</span> -->
			</div>
			<el-main>
				<router-view></router-view>
			</el-main>
			<el-footer :style="{backgroundColor:color}" v-show="isShow">
				<div>Rolum学驾照</div>
				<span>备案号：新ICP备2022000230号-1</span>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	import llqHeight from "sujs/su.js"
	export default {
		data() {
			return {
				activeIndex: '',
				isShow: true,
				// color: "#F76F5B",
				color: "#2A2A2A",
				// color: "#024DA0",
				color1: "#545c64",
				yuyan: [],
				login_info: false,
			}
		},
		methods: {
			shuaXin() {
				// 刷新改变索引菜单
				this.activeIndex = this.$route.fullPath
				//刷新改变语言
				this.yuyan = this.$language()
				// 防止刷新样式改变
				if (this.$route.name == '首页') {
					this.isShow = true
				} else {
					this.isShow = false
				}

			},
			onCancel() {
				this.$router.push({
					name: '首页'
				})
			},
			goCHengji() {
				this.$router.push('/grade')
			},
			dengLu() {
				this.$router.push('/login')
			},

			tuiChu() {
				sessionStorage.clear();
				this.$router.go(0)
			},

			handleSelect(key, keyPath) {
				this.activeIndex = key
				sessionStorage.setItem('currentMenu', this.activeIndex);
				console.log(key, keyPath);
			},
			language(e) {
				if (e == 'hanyu') {
					sessionStorage.setItem('language', 'hanyu');
					this.$store.commit('setLanguage', 'hanyu')
				}
				if (e == 'weiyu') {
					sessionStorage.setItem('language', 'weiyu');
					this.$store.commit('setLanguage', 'weiyu')
				}
				if (e == 'hayu') {
					sessionStorage.setItem('language', 'hayu');
					this.$store.commit('setLanguage', 'hayu')
				}
				// console.log(this.$store.state.language_num)
			},
			// 如果登陆成功那么直接跳转考试界面不是首页 的判断
			redirectExam() {
				if (sessionStorage.isLogin == 1) {
					this.$router.replace('/exam')
				}
			},
			login() {
				if (sessionStorage.isLogin) {
					if (sessionStorage.isLogin == 1) {
						this.login_info = true
					}
				} else {
					this.login_info = false
				}
			}
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.$router.go(0)
				this.yuyan = this.$language()
			}
		},
		created() {
			console.log(sessionStorage.getItem('currentMenu'))
			this.shuaXin()
			// 页面刷新相关操作
			this.login()
			this.activeIndex = sessionStorage.currentMenu || 1
		},
		mounted() {
			// 判断是否登陆
			this.login()
		}
	}
</script>


<style lang="scss" scoped>
	.tuchu-but:hover {
		color: #dddddd;
		cursor: pointer;
	}

	.tuchu-but:active {
		color: #323232;
	}

	.tuiChu {
		position: absolute;
		right: 20px;
		color: #FFFFFF;
		line-height: 60px;

		.el-button {
			margin-right: 10px;
			height: 35px;
		}
	}
	
	.index {
		position: absolute;
		left: 38px;
		color: #FFFFFF;
		font-size:16px;
		color: rgb(255, 208, 75); 
		border-bottom-color: rgb(255, 208, 75); 
		background-color: rgb(42, 42, 42);
		line-height: 40px;
		text-decoration: none;
	}

	.index_zhuye {
		height: 100%;
		width: 100%;
	}

	.guang-gao {
		height: 70px;

		img {
			height: 100%;
			width: 100%;
		}
	}

	.el-header,
	.el-footer {
		color: #fff;
		text-align: center;
		line-height: 60px;
	}

	.el-header {
		background-color: #F76F5B;
	}

	.el-footer {}

	.el-aside {
		background-color: #F76F5B;
		color: #333;
		text-align: center;
		line-height: 200px;
	}

	.el-main {
		padding: 0 !important;
		text-align: center;
		// background-image: url(https://s1.kaoshixing.com/website/website-5.0/images/index/img-ksx-banner-1.png?v=1d0de28369);

	}

	body>.el-container {
		margin-bottom: 40px;
	}

	.el-container:nth-child(5) .el-aside,
	.el-container:nth-child(6) .el-aside {
		line-height: 260px;
	}

	.el-container:nth-child(7) .el-aside {
		line-height: 320px;
	}


	// 自己写
	html,
	body,
	#app,
	.el-container {
		/*设置内部填充为0，几个布局元素之间没有间距*/
		padding: 0px;
		/*外部间距也是如此设置*/
		margin: 0px;
		/*统一设置高度为100%*/
		height: 100%;
		width: 100%;
	}

	.el-header {
		padding: 0 !important;
		display: flex;
		justify-content: center;
	}
</style>
