import axios from 'axios'
import router from "../router/index.js";

// 创建一个实例 用来管理一个请求，可以创建多个实例
import {
	GLOBAL_CONFIG_A
} from "../../global.config.js"

function yuYanUp(params) {
	if (sessionStorage.language) {
		if (sessionStorage.language == 'hanyu')
			params.lang = '1'
		if (sessionStorage.language == 'weiyu')
			params.lang = '2'
		if (sessionStorage.language == 'hayu')
			params.lang = '3'
	}else{
		params.lang = '1'
	}
}

function yuYanUpParams(data) {
	if (sessionStorage.language) {
		if (sessionStorage.language == 'hanyu')
			data.params = {
				lang : '1'
			}
		if (sessionStorage.language == 'weiyu')
			data.params = {
				lang : '2'
			}
		if (sessionStorage.language == 'hayu')
			data.params = {
				lang : '3'
			}
	}else{
		data.params = {
			lang : '1'
		}
	}
}


export function request(configs) {
	// 登陆是否失效
	if (!sessionStorage.token) {
		// console.log(router)
		router.push('/login')
	}
	const instance = axios.create({
		baseURL: GLOBAL_CONFIG_A.baseUrl,
		timeout: 600000,
		// header:{
		// 	token:sessionStorage.token
		// }
	})
	// 拦截器	请求
	instance.interceptors.request.use(config => {
		config.headers['token'] = sessionStorage.token
		// console.log("我是拦截器，拦截数据如下")
		// console.log(config)
		// console.log("拦截结束")
		config.headers.Authorization = window.sessionStorage.getItem('token')
		if (config.data.params) {
			yuYanUp(config.data.params)
		}else{
			yuYanUpParams(config.data)
		}
		return config
	}, err => {
		// console.log("拦截失败")
		// console.log(err)
		return err
	})
	// 拦截器 响应
	instance.interceptors.response.use(response => {
		// console.log("我是响应器，响应数据如下")
		// console.log(response)
		if (response.data.code == '10010002' || response.data.code == '90010007') {
			sessionStorage.clear();
			alert(response.data.msg)
			router.go(0)
		}
		if (response.data.data) {
			return response.data.data
		} else {
			return response.data
		}
		// console.log("响应结束")
	}, err => {
		// console.log("响应失败")
		// console.log(err)
		return err
	})

	// 返回的是promise对象 可以进行多次请求  instance里面传入的才是网络需要的配置
	return instance(configs)
}

// 测试接口
export function requestCeshi(configs) {
	const instance = axios.create({
		baseURL: 'https://www.liulongbin.top:8888/api/private/v1/',
		timeout: 5000,
	})
	// 拦截器	请求
	instance.interceptors.request.use(config => {
		// // console.log(config)
		// config.headers.Authorization = window.sessionStorage.getItem('token')
		return config
	}, err => {
		// // console.log(err)
		return err
	})
	// 拦截器 响应
	instance.interceptors.response.use(response => {
		// // console.log(response)
		return response
	}, err => {
		// // console.log(err)
		return err
	})

	// 返回的是promise对象 可以进行多次请求  instance里面传入的才是网络需要的配置
	return instance(configs)
}
